import React, {  useEffect }  from "react";
import {BrowserRouter, useHistory, matchPath, Route, Switch, useLocation} from "react-router-dom";
import Index from "./pages/index/index";

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={["/index", "/"]} component={Index} />
            </Switch>
        </BrowserRouter>
    );
}
