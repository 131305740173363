import React, {useRef, useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import home from "../../asserts/home.jpg";
import boyBox from "../../asserts/boyBox.png";
import girlBox from "../../asserts/girlBox.png";
import friends from "../../asserts/makeFriends.png";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function TOSDrawer() {
    const [state, setState] = React.useState({
        bottom: false,
    });

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, ["bottom"]: open });
            };

    return (
        <div>
            <React.Fragment key="tos">
                <Drawer
                    anchor="bottom"
                    open={state["bottom"]}
                    onClose={toggleDrawer(false)}
                >
                    <Box
                        sx={{ width: 'auto'  }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                      hahaHA
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Index() {

    const theme = useTheme();


    const  handleChangeIndex = (index: number) => {
        setValue(index);
    };


    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [state, setState] = React.useState({
        bottom: false,
    });

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, ["bottom"]: open });
            };

    return (
        <div className={"main-content"} >
                <Tabs variant="fullWidth" centered value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="我的纸条" {...a11yProps(0)} />
                    <Tab label="抽个对象" {...a11yProps(1)} />
                    <Tab label="我的摊位" {...a11yProps(2)} disabled/>
                </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                style={{
                    height:"90%"
                }}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0}>
                    <div style={{margin:"10px 5px"}}>
                        <ToggleButtonGroup
                            color="primary"
                            value="web"
                            exclusive
                            fullWidth
                        >
                            <ToggleButton value="web">我放入的纸条</ToggleButton>
                            <ToggleButton value="android">我抽到的纸条</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <Paper elevation={3}  variant="outlined" style={{margin:"0px 5px", backgroundColor:"rgb(179 179 179)", height:"48px", lineHeight:"48px", position:"relative"}}>
                        <div>
                            <p  style={{display:"inline"}}>有人回复纸条通知</p>
                            <div style={{position:"absolute", right:0, display:"inline"}}><Switch /></div>
                        </div>
                    </Paper>
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <div>
                        <img style={{width:"100%"}} src={friends} />
                    </div>
                    <div style={{marginTop:"-10px", textAlign:"center"}}>
                        <Chip style={{height:"45px", borderRadius:"24px"}} icon={<CampaignRoundedIcon color="action"/>}
                              label={"关注公众号第一时间接收纸条回复"} variant="outlined" onClick={()=>{console.log(123)}} />
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            p: 1,
                            m: 1,
                            marginTop:"-18px",
                            bgcolor: 'background.paper',
                        }}>
                        <div style={{backgroundImage:"url("+boyBox+")", backgroundSize:"cover",
                            backgroundRepeat:"no-repeat", width:"48%", height:"307px"}}>
                            <Stack
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="center"
                                height={"90%"}
                                spacing={2}
                            >
                                <Button variant="contained">放入1张男生纸条</Button>
                                <Button variant="contained">抽取1张男生纸条</Button>
                            </Stack>
                        </div>
                        <div style={{backgroundImage:"url("+girlBox+")", backgroundSize:"cover",
                            backgroundRepeat:"no-repeat", width:"48%", height:"307px"}}>
                            <Stack
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="center"
                                height={"90%"}
                                spacing={2}
                            >
                                <Button variant="contained">放入1张女生纸条</Button>
                                <Button variant="contained">抽取1张女生纸条</Button>
                            </Stack>
                        </div>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                    }}>
                        <div style={{fontSize:"14px"}}>
                            <p style={{display:"inline"}} onClick={toggleDrawer(true)}>玩家须知&nbsp;</p>
                            <Divider orientation="vertical" style={{display:"inline"}}  />
                            <p style={{display:"inline"}} onClick={toggleDrawer(true)}>&nbsp;用户协议&nbsp;</p>
                            <Divider orientation="vertical" style={{display:"inline"}}   />
                            <p style={{display:"inline"}} onClick={toggleDrawer(true)}>&nbsp;联系客服&nbsp;</p>
                            <Divider orientation="vertical" style={{display:"inline"}}   />
                            <p style={{display:"inline"}} onClick={toggleDrawer(true)}>&nbsp;投诉举报</p>
                        </div>
                    </Box>
                    <div>
                        <React.Fragment key="tos">
                            <Drawer
                                anchor="bottom"
                                open={state["bottom"]}
                                onClose={toggleDrawer(false)}
                            >
                                <Box
                                    sx={{ width: 'auto'  }}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                >
                                    <div style={{padding:"10px"}}>
                                        <h2>用户须知</h2>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊</p>
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊成为摊主，在线上或线下摆个摊</p>
                                        <p style={{width:"100%", textAlign:"center"}}>我已阅读并同意用户使用协议</p>
                                        <Button variant="contained" fullWidth>我知道了</Button>
                                    </div>
                                </Box>
                            </Drawer>
                        </React.Fragment>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div style={{"textAlign":"center", margin:"0 5px"}}>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p></p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p></p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p></p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p></p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <p></p>
                        <p>成为摊主，在线上或线下摆个摊</p>
                        <Button variant="contained" fullWidth>联系客服开通</Button>
                    </div>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}